#heroSection{
    /* background-color: white; */
    width: 100vw;
    height: 50vh;
    margin-bottom: 2rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/blogListHero.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
#heroContainer{
    position: absolute;
    text-align: center;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#pageTitle{
    color: rgb(241,234,228);
    font-size: 3em;
}
#pageDescription{
    padding-left: 1rem;
    border-left: 3px solid rgb(139, 139, 139);
    background-color: white;
    padding: 1em;
    border-radius: 2em;
}


#blogListItem {
    border: none;
}


.productCard{
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    color: white; 
}

.cardImage{
    opacity: 0.25;
    transition: transform 0.3s ease;
    transform-origin: center center;
}

.productCard:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    
}

.productCard:hover .cardImage{
    transform: scale(1.1);
}

#readBlogButton{
    background-color: #f5f5dc;
    color: #000000;
    border: none;
}
#readBlogButton:hover{
    background-color: rgb(20, 57, 95);
    color: white
}
/* #imageOverlay{
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.9) 100%);
    transition: background-color 0.3s ease;
}

#productCard:hover #imageOverlay{
    background: linear-gradient(to bottom, transparent 0%, rgba(245, 245, 220, 0.7) 100%);
} */


.card-title{
    font-size: 2em;
}


