

body{
    background-image:url("../assets/background.svg");
    background-size: cover;
    background-repeat: repeat;
    width: '100vw';
    height: '100vh';

    font-family: 'Helvetica', sans-serif;
}