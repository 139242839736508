
#blogPost{
    background-color: rgb(255, 255, 255);
    min-height: 100%;
    font-family: Helvetica, sans-serif;
    /* background-repeat: repeat ; */
}

.bquote{
    border-left: 3px solid #ccc;
    font-style: italic;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: 1em;
}

#fbIframe{
    background-color: rgb(211, 224, 238);
    border-radius: 2em;
    /* height: 15rem; */
}

#followPage{
    border-bottom: 1px solid rgb(183, 178, 178);
}
#followText{
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 1rem;
}

