#homeMenuLink{
    text-decoration: none;

}

#blogButton{
    background-color: #1251b0;
    width: 10rem;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    border-radius: 2em;
}

#blogButton:hover, #blogButton:focus{
    background-color: #d5d841;
    color: black;
    border: 3px solid black;
}




#navbarBrandText{
    font-size: 0.8rem;
    font-family: Helvetica, sans-serif;
}

#navPURR{
    color: rgb(11, 78, 178);
}

#navMEOW{
    color:rgb(190, 193, 13);
}