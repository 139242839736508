p{
    font-family: 'Helvetica', sans-serif;
}

#landingPageRow{
    height: 80vh;
}

#hero-title{
    font-family: 'Helvetica', sans-serif;
    font-size: 4em;
}

#hero-purr{
    color: rgb(73, 126, 206);
}

#hero-meow{
    color:rgb(159, 154, 7);
}

#hero-picture{
    border-radius: 1rem;
    border: 2px solid rgb(56, 56, 56);
    background-color: rgb(207,234,249);
    
}

#callToAction{
    background-color: #1251b0;
    border-radius: 2em;
}

#callToAction:hover, #callToAction:focus{
    background-color: #d5d841;
    color:black
}

